@font-face {
    font-family: ethnocentric rg;
    src: url("./assets/fonts/ethnocentric rg.otf") format("opentype");
}

html, body {
    overflow-x: hidden;
    padding: 0 0;
    margin: 0 0;
    box-sizing: border-box;
}

.spin {
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ctas {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    gap: 16px; /* Adjust the gap between items as needed */
}

.ctas button {
    padding: 28px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
