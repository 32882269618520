.moduleWrapper {
  font-family: Arial, Helvetica, sans-serif;
  :global {
    .centerContent {
      font-family: Arial, Helvetica, sans-serif;

      height: calc(100vh - 65px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
    }

    .ready {
      cursor: pointer;
      display: inline-block;
      padding: 6px 31px;
      background: #FFF;
      color: #000;
      border-radius: 12px;
      font-size: 23px;
      font-weight: bold;
    }

    .loadingArea {
      position: relative;
      z-index: 100;
      width: 1000px;
      margin: 0 auto;
      //left: calc(50vw - 500px);
      --percent: 0%;

      .loadingBar {
        transition: 1s;
        height: 58px;
        width: var(--percent, 50%);
        margin-top: 80px;
        border-radius: 148px;
        z-index: 10000;
        position: relative;
      }

      .absolute {
        padding-top: 80px;
      }

      .indicator {
        width: 100%;
        height: 58px;
        //background: rgb(255 255 255 / 8%);
        margin-top: -66px;
        border-radius: 150px;
        z-index: 20000;
        position: absolute;
        border: 5px solid #fff;
      }

      .loadingText {
        z-index: 20000;
        position: absolute;
        color: #FFF;
        width: 100%;
        text-align: center;
        font-size: 40px;
        margin-top: 22px;
      }

      .rotate {
        margin-top: 8px;
        z-index: 10000;
        transition: 1s;
        position: absolute;
        left: var(--percent, 50%);
        margin-left: -95px;
      }
    }

    .relative {
      position: relative;
      width: 100%;
      height: 320px;
      padding-top: 10%;
    }

    .heading {
      z-index: 2000;
      position: absolute;
      width: 100%;
      color: #FFF;
      text-align: center;
      font-size: 50px;
    }

    .video-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .twoColumns {
      justify-content: center;
    }

    .labelDate {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }

    .quickFilter {
      display: flex;
      gap: 10px;
      div {
        cursor: pointer;
        &:hover {
          color: #d97100;
        }
      }
    }

    .formFieldDate label {
      width: 100%;
    }

    .statuses {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      //max-width: 960px;
      margin: 0 auto;

      .status {
        //width: 120px;
        border: 1px solid #bbbbbb;
        height: 60px;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: #ffd4a72b;

        .counter {
          font-size: 30px;
        }
      }
    }
  }
}
