.moduleWrapper {
  text-align: center;
  margin: 0 auto !important;
  color: #FFF;

  :global {
    height: 100vh;
    width: 100%;
    background-image: url('../../assets/images/bg.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .thankYou {
      font-size: 40px;
      margin-top: -50px;
    }

    .first {
      margin-top: -50px;
      font-size: 20px;
      font-family: Arial, Helvetica, sans-serif;
    }

    .second, .third {
      font-family: ethnocentric rg, serif;
      font-size: 54px;
      line-height: 51px;
      font-weight: normal;
    }

    .fourth {
      padding: 35px 0;
      font-family: Arial, serif;
      font-size: 16px;
      line-height: 25px;
      font-weight: bold;
    }
  }
}
